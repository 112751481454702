import { ActionType, createStandardAction } from 'typesafe-actions';
import { PeriodFrequency } from '../../types/PeriodFrequency';
import { FrequencyRecord } from '../../types/FrequencyRecord';
import { FuturesRaw, FuturesRecords } from '../../types/FuturesRecord';
import { RevisionRecord } from '../../types/RevisionRecord';

interface RecordsFrequencyMeta {
  frequency: PeriodFrequency,
  productID: string,
}

export interface InitializeFrequencyMeta {
  frequency: PeriodFrequency,
  areaID: number,
  hasUsaMap?: boolean,
}

export const recordsActions = {
  frequency: {
    initializeFrequency: createStandardAction('@records_frequency/INITIALIZE_FREQUENCY')<string, InitializeFrequencyMeta>(),
    initializeUnitsForFrequency: createStandardAction('@records_frequency/INITIALIZE_UNITS_FOR_FREQUENCY')<string, PeriodFrequency>(),
    fetchFrequency: createStandardAction('@records_frequency/FETCH_FREQUENCY')<string, InitializeFrequencyMeta>(),
    addFrequency: createStandardAction('@records_frequency/ADD_FREQUENCY_TO_RECORDS')<FrequencyRecord, RecordsFrequencyMeta>(),
  },
  futures: {
    initializeFutures: createStandardAction('@records_futures/INITIALIZE')(),
    fetchFuturesByReportPeriod: createStandardAction('@records_futures/FETCH_FUTURES_BY_REPORT_PERIOD')<Date>(),
    fetchFuturesByContractMonth: createStandardAction('@records_futures/FETCH_FUTURES_BY_CONTRACT_MONTH')<Date>(),
    fetchFuturesByNearbyIndex: createStandardAction('@records_futures/FETCH_FUTURES_BY_NEARBY_INDEX')<number>(),
    setFutures: createStandardAction('@records_futures/SET_FUTURES')<string, {recordsData: FuturesRecords, rawRecords: FuturesRaw[]}>(),
  },
  revision: {
    addRevision: createStandardAction('@records_revision/ADD_REVISION_TO_RECORDS')<RevisionRecord, RecordsFrequencyMeta>(),
  },
};

export type RecordsActions = ActionType<typeof recordsActions>;
