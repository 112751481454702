import axios from 'axios';
import { PeriodFrequency } from '../types/PeriodFrequency';
import getFrequencyID from '../ducks/products/utils/getFrequencyID';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 20000,
});

const dataAPI = {
  fetchNavigationMenu: () => api.get('Menu'),

  fetchMenu: (menuID: number) => api.get(`Product?menuItemId=${menuID}`),

  fetchProduct: (
    productID: string,
    options?: { frequency?: PeriodFrequency, area?: number },
  ) => {
    let URI = `Product/${productID}`;
    if (options) {
      if (options.frequency && options.area) {
        const frequencyID = getFrequencyID(options.frequency);
        URI = `Product/${productID}?frequencyID=${frequencyID}&areaID=${options.area}`;
      } else if (options.area) {
        URI = `Product/${productID}?areaID=${options.area}`;
      } else if (options.frequency) {
        const frequencyID = getFrequencyID(options.frequency);
        URI = `Product/${productID}?frequencyID=${frequencyID}`;
      }
    }
    return api.get(URI);
  },

  fetchFrequencyRecord: (productID: string, frequency: PeriodFrequency, from: string, to: string, areaID: string | number) =>
    api.get(`Value_${frequency}?$filter=ProductID eq ${productID} and AreaID eq ${areaID} and Active eq 1 and
    ReportPeriod ge datetime'${from}' and ReportPeriod le datetime'${to}'
    &$select=AreaID,Value,ReportPeriod,CollectionDate${frequency === 'Weekly' ? ',WeekEnding' : ''}
    &$orderby=ReportPeriod asc, CollectionDate desc`),

  fetchUsaFrequencyRecord: (productID: string, frequency: PeriodFrequency, from: string, to: string) =>
      api.get(`Value_${frequency}?$filter=ProductID eq ${productID} and AreaID le 53 and Active eq 1 and
      ReportPeriod ge datetime'${from}' and ReportPeriod le datetime'${to}'
      &$select=AreaID,Value,ReportPeriod,CollectionDate&$orderby=ReportPeriod asc, CollectionDate desc`),

  fetchRecordsForUnitConversion: (productID: string, frequency: PeriodFrequency) =>
    api.get(`Value_${frequency}?$filter=ProductID eq ${productID}
    &$select=AreaID,Value,ReportPeriod&$orderby=ReportPeriod asc, CollectionDate desc`),

  fetchFuturesRecordWithTradeDate: (productID: string, from: string, to: string, fromTradingDate: string, toTradingDate: string) =>
    api.get(`Value_Futures?$filter=ContractMonth
   ge datetime'${from}' and ContractMonth le datetime'${to}' and ProductID eq
   ${productID} and ReportPeriod ge datetime'${fromTradingDate}' and ReportPeriod le datetime'${toTradingDate}'
   &$select=ProductID,AreaID,OpenPrice,HighPrice,LowPrice,ClosePrice,Volume,ReportPeriod,
   ContractMonth,SourceID,OpenInterest&$orderby=ContractMonth, ReportPeriod asc`),

  fetchFuturesRecordWithContractMonth: (productID: string, from: string, to: string) =>
    api.get(`Value_Futures?$filter=ContractMonth
   ge datetime'${from}' and ContractMonth le datetime'${to}' and ProductID eq
   ${productID}&$select=ProductID,AreaID,OpenPrice,HighPrice,LowPrice,ClosePrice,Volume,ReportPeriod,
   ContractMonth,SourceID,OpenInterest&$orderby=ContractMonth asc, ReportPeriod asc`),

  fetchFuturesRecordWithNearbyIndex: (productID: string, from: string, to: string, index: number) =>
    api.get(`Value_Futures?$filter=ContractMonth
   ge datetime'${from}' and ContractMonth le datetime'${to}' and ProductID eq ${productID} and NearbyIndex eq ${index}
   &$select=ProductID,AreaID,OpenPrice,HighPrice,LowPrice,ClosePrice,Volume,ReportPeriod,
   ContractMonth,SourceID,OpenInterest&$orderby=ContractMonth asc, ReportPeriod asc`),

  fetchUnits: () => api.get('unit/conversion'),

  // filters
  getPublishers: () => api.get('UDM_Catalog_Publishers'),
  getReports: () => api.get('UDM_Catalog_Reports'),
  getAreas: () => api.get('UDM_Catalog_Areas'),
  getCategories: () => api.get('UDM_Catalog_Categories'),
  getMeasures: () => api.get('UDM_Catalog_Measures'),
  // https://udmdirect-odata-develop.azurewebsites.net/web/area/classification
  getCounties: () => api.get('area/ISO 3166-1'),
  // Maybe for later :D
  getDashboards: () => api.get('UDM_Catalog_Dashboards'),
  getSources: () => api.get('UDM_Catalog_Sources'),
  getTables: () => api.get('UDM_Catalog_Tables'),
  getUnits: () => api.get('UDM_Catalog_Units'),
};

export default dataAPI;
