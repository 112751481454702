import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { recordsActions } from '../actions';
import { getProductPageProductID } from '../../productPage/selectors';
import createFuturesRecord from './utils/createFuturesRecord';
import dataAPI from '../../../services/dataServices';
import mapFuturesRecordsDtoToFuturesRecords from './utils/mapFuturesRecordsDtoToFuturesRecords';
import { AxiosResponse } from 'axios';
import { FuturesRecordDto } from '../../../types/dto/FuturesRecordDto';
import { FuturesRecords } from '../../../types/FuturesRecord';
import { addYears, endOfMonth, format, isSameDay, startOfMonth, subDays, subMonths } from 'date-fns';
import { toast } from 'react-toastify';
import { productPageActions } from '../../productPage/actions';

function* initializeFuturesRecords() {
  try {
    const productID = yield select(getProductPageProductID);
    const date = new Date();
    const from = new Date(date.getFullYear(), date.getMonth()).toISOString().slice(0, 10);
    const to = new Date(date.getFullYear() + 2, date.getMonth()).toISOString().slice(0, 10);
    const toTradingDate = new Date().toISOString().slice(0, 10);
    const fromTradingDate = subMonths(new Date(), 2).toISOString().slice(0, 10);
    const futuresRecordsDto: AxiosResponse<FuturesRecordDto> = yield call(dataAPI.fetchFuturesRecordWithTradeDate,
      productID, from, to, fromTradingDate, toTradingDate);
    const futuresRecords = yield mapFuturesRecordsDtoToFuturesRecords(futuresRecordsDto);
    const latestTradingDate = futuresRecordsDto.data.value[futuresRecordsDto.data.value.length - 1].ReportPeriod;
    yield put(productPageActions.setFuturesTradingDate(new Date(latestTradingDate)));
    const records = yield call(createFuturesRecord, futuresRecords);
    yield put(recordsActions.futures.setFutures(productID, { recordsData: records, rawRecords: futuresRecords }));
  } catch (e) {
      console.error(e);
  }
}

function* fetchFuturesRecords(action: ActionType<typeof recordsActions.futures.fetchFuturesByReportPeriod>) {
  try {
    const productID = yield select(getProductPageProductID);
    const toTradingDate = format(action.payload, 'YYYY-MM-DD');
    const fromTradingDate = format(subDays(action.payload, 35), 'YYYY-MM-DD');
    const date = new Date(Date.now());
    const from = format(new Date(), 'YYYY-MM-DD');
    const to = format(new Date(date.getFullYear() + 2, date.getMonth()), 'YYYY-MM-DD');
    const futuresRecordsDto: AxiosResponse<FuturesRecordDto> =
      yield call(dataAPI.fetchFuturesRecordWithTradeDate, productID, from, to, fromTradingDate, toTradingDate);
    const toTradingDateFutures = futuresRecordsDto.data.value.filter(record => isSameDay(record.ReportPeriod, toTradingDate));
    if (toTradingDateFutures.length !== 0) {
      yield put(productPageActions.setFuturesTradingDate(new Date((action.payload).toString() + 'z')));
      const futuresRecords = yield mapFuturesRecordsDtoToFuturesRecords(futuresRecordsDto);
      const records: FuturesRecords = yield call(createFuturesRecord, futuresRecords);
      yield put(recordsActions.futures.setFutures(productID, { recordsData: records, rawRecords: futuresRecords }));
    } else {
      const records: FuturesRecords = yield call(createFuturesRecord, []);
      yield put(recordsActions.futures.setFutures(productID, { recordsData: records, rawRecords: [] }));
      toast.error(`Trade date ${format(toTradingDate, 'MMM DD, YYYY')} is not available.`);
    }
  } catch (e) {
    console.error(e);
  }
}

function* fetchFuturesByContractMonth(action: ActionType<typeof recordsActions.futures.fetchFuturesByContractMonth>) {
  const productID = yield select(getProductPageProductID);
  const toContractMonth = format(endOfMonth(action.payload), 'YYYY-MM-DD');
  const fromContractMonth = format(startOfMonth(action.payload), 'YYYY-MM-DD');
  try {
    const futuresRecordsDto: AxiosResponse<FuturesRecordDto> =
      yield call(dataAPI.fetchFuturesRecordWithContractMonth, productID, fromContractMonth, toContractMonth);
    const futuresRecords = yield mapFuturesRecordsDtoToFuturesRecords(futuresRecordsDto);
    const records: FuturesRecords = yield call(createFuturesRecord, futuresRecords);
    yield put(recordsActions.futures.setFutures(productID, { recordsData: records, rawRecords: futuresRecords }));
    yield put(productPageActions.setFuturesContractMonth(endOfMonth(action.payload)));
  } catch (e) {
    toast.error(`Trade date ${format(action.payload, 'MMM DD, YYYY')} is not available.`);
  }
}

function* fetchFuturesByNearbyIndex(action: ActionType<typeof recordsActions.futures.fetchFuturesByNearbyIndex>) {
  try {
    const productID = yield select(getProductPageProductID);
    const date = new Date(Date.now());
    const from = format(date, 'YYYY-MM-DD');
    const to = format(addYears(date, 2), 'YYYY-MM-DD');

    const futuresRecordsDto: AxiosResponse<FuturesRecordDto> =
      yield call(dataAPI.fetchFuturesRecordWithNearbyIndex, productID, from, to, action.payload);
    const futuresRecords = yield mapFuturesRecordsDtoToFuturesRecords(futuresRecordsDto);
    const records = yield call(createFuturesRecord, futuresRecords);
    yield put(recordsActions.futures.setFutures(productID, { recordsData: records, rawRecords: futuresRecords }));

    yield put(productPageActions.setFuturesNearbyIndex(action.payload));
  } catch (e) {
    console.error(e);
  }
}

function* futuresRecordWatcher() {
  yield all([
    takeEvery(getType(recordsActions.futures.initializeFutures), initializeFuturesRecords),
    takeLatest(getType(recordsActions.futures.fetchFuturesByReportPeriod), fetchFuturesRecords),
    takeLatest(getType(recordsActions.futures.fetchFuturesByContractMonth), fetchFuturesByContractMonth),
    takeLatest(getType(recordsActions.futures.fetchFuturesByNearbyIndex), fetchFuturesByNearbyIndex),
  ]);
}

export default futuresRecordWatcher;
