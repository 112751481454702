import * as React from "react";
import { RouterState } from "../CatalogScene";
import catalogAPI from "src/services/catalogServices";
import CatalogSortContainer from "../../../containers/CatalogSortContainer/CatalogSortContainer";
import LangPicker from "../../../components/shared/LangPicker/LangPicker";
import SeriesLink from "../../../components/Catalog/styles/SeriesLink/SeriesLink";
import { Link } from "react-router-dom";
import PaginationWrapper from "src/components/AdminPanel/adminPanel/PaginationWrapper";
import PerPage from "../../../components/shared/Pagination/perPage";
import PaginationContainer from "../../../components/Catalog/styles/PaginationContainer/PaginationContainer";
import PaginationBox from "../../../components/Catalog/styles/PaginationBox/PaginationBox";
import { RootState } from "src/ducks/store";
import { connect } from "react-redux";
import { getCatalogSort } from "../../../ducks/catalog/selectors";

type OwnProps = {
  search: string;
  locationState: RouterState;
};

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

const CatalogReportsSeriesScene: React.FC<Props> = ({
  search,
  sort,
  locationState,
}) => {
  const [translated, setTranslation] = React.useState(
    locationState && locationState.translated ? locationState.translated : false
  );
  const [pageSize, setPageSize] = React.useState(25);
  const [page, setPage] = React.useState(1);
  const [seriesList, setList] = React.useState<
    Array<{ id: number; name: string }>
  >([]);
  const [hasNext, setHasNext] = React.useState(false);

  const switchClick = () => setTranslation(!translated);

  const onPageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setPageSize(Number(event.target.value));

  const setNextPage = () => setPage(page - 1);
  const setPreviousPage = () => setPage(page + 1);

  React.useEffect(() => {
    (async () => {
      const reportCode =
        locationState && locationState.nested
          ? [
              locationState &&
                String(
                  locationState.nested[
                    locationState && locationState.nested.length - 1
                  ].id
                ),
            ]
          : [];
      try {
        const response = await catalogAPI.searchSeries(
          {
            pageSize,
            translated,
            page: page - 1,
            contains: search.length >= 3 ? search : "",
            startsWith: sort === "All" || sort === "Recent" ? "" : sort,
          },
          { reportCode }
        );
        setList(response.data);
        setHasNext(response.hasNext);
      } catch (err) {
        console.error("Error: catalogAPI.searchSeries failed!", err);
      }
    })();
  }, [translated, search, pageSize, page, sort]);

  return (
    <>
      <div style={{ display: "flex", marginBottom: "10px" }}>
        <CatalogSortContainer />
        <LangPicker active={translated} onClick={switchClick} />
      </div>
      {seriesList.map(({ id, name }) => (
        <SeriesLink key={id}>
          <Link to={`/product/${id}`}>{name}</Link>
        </SeriesLink>
      ))}
      <PaginationWrapper>
        {/*TODO: use dataLength from backend*/}
        <PerPage
          dataLength={100}
          perPage={pageSize}
          perPageClickHandler={onPageSizeChange}
        />
        <PaginationContainer>
          {page > 1 && (
            <PaginationBox onClick={setNextPage}>Previous</PaginationBox>
          )}
          {hasNext && (
            <PaginationBox onClick={setPreviousPage}>Next</PaginationBox>
          )}
        </PaginationContainer>
      </PaginationWrapper>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({ sort: getCatalogSort(state) });

export default connect(mapStateToProps)(CatalogReportsSeriesScene);
