import produce from 'immer';
import { FuturesRaw, FuturesRecords } from '../../../types/FuturesRecord';
import { recordsActions, RecordsActions } from '../actions';
import { getType } from 'typesafe-actions';
import createUniqueArray from '../../../utils/createUniqueArray';

interface FuturesRecordsState {
  allProducts: number[],
  productsById: FuturesRecordProduct,
}

interface FuturesRecordProduct {
  records: { [productID: string] : FuturesRecords },
  rawRecords: { [productID: string] : FuturesRaw[] },
}

const initialState: FuturesRecordsState = {
  allProducts: [],
  productsById: {
    records: {},
    rawRecords: {},
  },
};

export const futuresRecordReducer = produce<FuturesRecordsState, RecordsActions>((state, action) => {
  switch (action.type) {
    case getType(recordsActions.futures.setFutures): {
      const { payload: productID } = action;
      state.allProducts =
        createUniqueArray<number>([...state.allProducts, Number(productID)]);
      state.productsById.records = {[productID]: action.meta.recordsData};
      state.productsById.rawRecords = {[productID]: action.meta.rawRecords};
      return;
    }
  }
}, initialState);

export default futuresRecordReducer;
