import produce from 'immer';
import { PeriodFrequency } from '../../types/PeriodFrequency';
import { productPageActions, ProductPageActions } from './actions';
import { getType } from 'typesafe-actions';
import { ExploreData } from '../../utils/enums';
import { ContextTablesConfigIDs } from '../contextTable/contextTables.config';
import { endOfMonth } from 'date-fns';

export enum ComparisonPeriod {
  None = 0,
  Day = 1,
  Week = 2,
  Month = 3,
}

export enum ContractMonthRange {
  '1M' = 1,
  '3M' = 3,
  '6M' = 6,
  '1YR' = 12,
  // TODO: find better solution!
  ALL = 999,
}

export interface ProductPageState {
  pageState: pageState,
  productID: number | null,
  areaID: number | null,
  unitID: number | null,
  chartID: number | null,
  scale: number,
  futuresTradingDate: Date,
  frequency?: PeriodFrequency,
  contextTable: ContextTablesConfigIDs,
  exploreDataView: ExploreData,
  displayDateRange: DisplayDateRange,
  comparisonPeriod?: ComparisonPeriod,
  contractMonthRange?: ContractMonthRange,
  contextSorting?: { by: string, direction: 'ascending' | 'descending' },
  translated: boolean,
  futuresDisplayType: string,
  futuresContractMonth: Date,
  futuresNearbyIndex: number,
}

export interface DisplayDateRange {
  from: Date,
  to: Date,
}

export enum pageState {
  INITIALIZING = 'initializing',
  LOADING = 'loading',
  LOADED = 'loaded',
}

export const initialState: ProductPageState = {
  pageState: pageState.INITIALIZING,
  productID: null,
  areaID: null,
  unitID: null,
  chartID: null,
  scale: 0,
  futuresTradingDate: new Date(),
  contextTable: 0,
  comparisonPeriod: ComparisonPeriod.None,
  contractMonthRange: ContractMonthRange['6M'],
  exploreDataView: ExploreData.TIME_ENDING,
  displayDateRange: {
    from: new Date(new Date().getFullYear() - 5, 0),
    to: new Date(new Date().getFullYear(), new Date().getMonth()),
  },
  translated: false,
  futuresDisplayType: 'TradeDate',
  futuresContractMonth: endOfMonth(new Date()),
  futuresNearbyIndex: 1,
};

export const productPageReducer = produce<ProductPageState, ProductPageActions>(
  (state: ProductPageState, action) => {
    switch (action.type) {
      case getType(productPageActions.setProduct):
        state.productID = action.payload.productID;
        state.areaID = action.payload.areaID;
        state.chartID = action.payload.chartID;
        state.frequency = action.meta.frequency;
        state.unitID = action.meta.unitID;
        state.scale = action.meta.scale;
        state.contextTable = 0;
        if (action.meta.lastReportPeriod) {
          state.displayDateRange.to = action.meta.lastReportPeriod;
          state.displayDateRange.from = new Date(action.meta.lastReportPeriod.getFullYear() - 5, action.meta.lastReportPeriod.getMonth());
        }
        return;
      case getType(productPageActions.pageStatus.loading):
        state.pageState = pageState.LOADING;
        return;
      case getType(productPageActions.pageStatus.loaded):
        state.pageState = pageState.LOADED;
        return;
      case getType(productPageActions.setSelectedChart):
        state.chartID = action.payload;
        return;
      case getType(productPageActions.setDisplayDateRange):
        state.displayDateRange.from = action.payload.from;
        state.displayDateRange.to = action.payload.to;
        return;
      case getType(productPageActions.setExploreDataTo.timeChange):
        state.exploreDataView = ExploreData.TIME_CHANGE;
        return;
      case getType(productPageActions.setExploreDataTo.timeEnding):
        state.exploreDataView = ExploreData.TIME_ENDING;
        return;
      case getType(productPageActions.setExploreDataTo.timeOnTime):
        state.exploreDataView = ExploreData.TIME_ON_TIME;
        return;
      case getType(productPageActions.setExploreDataTo.yearOnYear):
        state.exploreDataView = ExploreData.YEAR_ON_YEAR;
        return;
      case getType(productPageActions.changeUnitID):
        state.unitID = action.payload;
        return;
      case getType(productPageActions.changeScale):
        state.scale = action.payload;
        return;
      case getType(productPageActions.setContextTable):
        state.contextTable = action.payload;
        return;
      case getType(productPageActions.setSorting):
        state.contextSorting = { by: action.payload, direction: action.meta };
        return;
      case getType(productPageActions.reinitializeState):
        state.pageState = pageState.INITIALIZING;
        state.productID = null;
        state.areaID = null;
        state.unitID = null;
        state.chartID = null;
        state.contextTable = 0;
        state.exploreDataView = ExploreData.TIME_ENDING;
        state.displayDateRange = {
          from: new Date(new Date().getFullYear() - 5, 0),
          to: new Date(new Date().getFullYear(), new Date().getMonth()),
        };
        delete state.frequency;
        delete state.contextSorting;
        return;
      case getType(productPageActions.changeComparisonPeriod):
        state.comparisonPeriod = action.payload;
        return;
      case getType(productPageActions.changeContractMonth):
        state.contractMonthRange = action.payload;
        return;
      case getType(productPageActions.toggleTranslation):
        state.translated = !state.translated;
        return;
      case getType(productPageActions.setFuturesTradingDate):
        state.futuresTradingDate = action.payload;
        return;
      case getType(productPageActions.setFuturesDisplayType):
        state.futuresDisplayType = action.payload;
        return;
      case getType(productPageActions.setFuturesContractMonth):
        state.futuresContractMonth = action.payload;
        return;
      case getType(productPageActions.setFuturesNearbyIndex):
        state.futuresNearbyIndex = action.payload;
        return;
    }
  }, initialState,
);
