import { RootState } from '../store';
import { PeriodFrequency } from '../../types/PeriodFrequency';
import { ExploreData } from '../../utils/enums';
import { DisplayDateRange, pageState } from './reducers';
import { ChartConfigIDs } from '../charts/utils/charts.config';
import { createSelector } from 'reselect';
import { getSelectedUnit } from '../units/selectors';
import { getUnitFromProduct } from '../products/selectors';

export const getProductPageProductID = (state: RootState): number | null => state.productPage.productID;
export const getProductPageAreaID = (state: RootState): number | null => state.productPage.areaID;
export const getExploreDataView = (state: RootState): ExploreData => state.productPage.exploreDataView;
export const getUnitID = (state: RootState): number | null => state.productPage.unitID;
export const getChartID = (state: RootState): ChartConfigIDs | null => state.productPage.chartID as any;
export const getDisplayDateRange = (state: RootState): DisplayDateRange => state.productPage.displayDateRange;
export const getPageState = (state: RootState): pageState => state.productPage.pageState;
export const getComparisonPeriod = (state: RootState) => state.productPage.comparisonPeriod;
export const getContractMonthRange = (state: RootState) => state.productPage.contractMonthRange;
export const getScale = (state: RootState) => state.productPage.scale;
export const getSelectedContextTable = (state: RootState) => state.productPage.contextTable;
export const getContextSorting = (state: RootState) => state.productPage.contextSorting;
export const getFuturesTradingDate = (state: RootState) => state.productPage.futuresTradingDate;
export const getFuturesDisplayType = (state: RootState) => state.productPage.futuresDisplayType;
export const getFuturesContractMonth = (state: RootState) => state.productPage.futuresContractMonth;
export const getFuturesNearbyIndex = (state: RootState) => state.productPage.futuresNearbyIndex;

export const getProductPageFrequency = (state: RootState): PeriodFrequency | null =>
  state.productPage.frequency !== undefined ? state.productPage.frequency : null;

export const getChartUnitName = createSelector(
  getUnitFromProduct,
  getSelectedUnit,
  (defaultUnit, selectedUnit) => {
    if (!defaultUnit) {
      return '';
    }

    if (!selectedUnit) {
      return defaultUnit.name;
    }
    return selectedUnit.name;
  },
);
