import { FrequencyRecordRaw } from '../../../../types/FrequencyRecord';
import { PeriodFrequency } from '../../../../types/PeriodFrequency';
import { getFrequencyTimestamp, getTimeIndexFromTimestamp, getYearFromTimestamp } from '../../frequency/utils/timeFunctions';
import { RevisionRecord } from '../../../../types/RevisionRecord';

const createRevisionRecord = (records: FrequencyRecordRaw[], frequency: PeriodFrequency): RevisionRecord => {
  const result: RevisionRecord = { allAreas: [] };
  records.forEach((record: FrequencyRecordRaw) => {
    if (!result[record.areaID]) {
      result[record.areaID] = { allRecords: [] };
    }
    const reportPeriodTimestamp = getFrequencyTimestamp(record.reportPeriod, frequency);
    const recordValues = {
      value: record.value,
      index: getTimeIndexFromTimestamp(reportPeriodTimestamp),
      year: getYearFromTimestamp(reportPeriodTimestamp),
      date: record.reportPeriod,
      timestamp: reportPeriodTimestamp,
    };

    if (!result[record.areaID][reportPeriodTimestamp]) {
      result[record.areaID][reportPeriodTimestamp] = [{
        ...recordValues,
        collectionDate: record.collectionDate,
      }];
    } else {
      result[record.areaID][reportPeriodTimestamp].push({
        ...recordValues,
        collectionDate: record.collectionDate,
      });
    }
  });
  result.allAreas = Object.keys(result).filter(value => value !== 'allAreas').map(Number);
  result.allAreas.forEach((area: number) => {
    result[area].allRecords = Object.keys(result[area]).filter(value => value !== 'allRecords').map(Number);
  });
  return result;
};

export default createRevisionRecord;
