import { ActionType, createStandardAction } from 'typesafe-actions';
import { PeriodFrequency } from '../../types/PeriodFrequency';
import { ComparisonPeriod, ContractMonthRange, DisplayDateRange } from './reducers';
import { ContextTablesConfigIDs } from '../contextTable/contextTables.config';

interface SetProductPayload {
  productID: number,
  areaID: number,
  chartID: number,
}

interface SetProductMeta {
  frequency: PeriodFrequency,
  unitID: number,
  lastReportPeriod?: Date,
  scale: number,
}

export const productPageActions = {
  reinitializeState: createStandardAction('@productPage/REINITIALIZE_STATE')(),
  setProduct: createStandardAction('@productPage/SET_PRODUCT')<SetProductPayload, SetProductMeta>(),
  setContextTable: createStandardAction('@productPage/SET_CONTEXT_TABLE')<ContextTablesConfigIDs>(),
  setExploreDataTo: {
    timeEnding: createStandardAction('@productPage_exploreDataView/TIME_ENDING')(),
    timeChange: createStandardAction('@productPage_exploreDataView/TIME_CHANGE')(),
    yearOnYear: createStandardAction('@productPage_exploreDataView/YEAR_ON_YEAR')(),
    timeOnTime: createStandardAction('@productPage_exploreDataView/TIME_ON_TIME')(),
  },
  setSelectedChart: createStandardAction('@productPage_chartID/SET')<number>(),
  setDisplayDateRange: createStandardAction('@productPage_displayDateRange/SET')<DisplayDateRange>(),
  changeUnitID: createStandardAction('@productPage_unitID/CHANGE_UNIT_ID')<number>(),
  pageStatus: {
    loading: createStandardAction('@productPage_pageStatus/LOADING')(),
    loaded: createStandardAction('@productPage_pageStatus/LOADED')(),
  },
  changeComparisonPeriod: createStandardAction('@productPage/CHANGE_COMPARISON_PERIOD')<ComparisonPeriod>(),
  changeContractMonth: createStandardAction('@productPage/CHANGE_CONTRACT_MONTH')<ContractMonthRange>(),
  changeScale: createStandardAction('@productPage/CHANGE_SCALE')<number>(),
  setSorting: createStandardAction('@productPage/SET_SORTING')<string, 'ascending' | 'descending'>(),
  toggleTranslation: createStandardAction('@productPage/TOGGLE_TRANSLATION')(),
  setFuturesTradingDate: createStandardAction('@productPage/SET_TRADING_DATE')<Date>(),
  setFuturesDisplayType: createStandardAction('@productPage/SET_FUTURES_DISPLAY_TYPE')<'ContractMonth' | 'TradeDate' | 'NearbyIndex'>(),
  setFuturesContractMonth: createStandardAction('@productPage/SET_FUTURES_CONTRACT_MONTH')<Date>(),
  setFuturesNearbyIndex: createStandardAction('@productPage/SET_FUTURES_NEARBY_INDEX')<number>(),
};

export type ProductPageActions = ActionType<typeof productPageActions>;
