import produce from 'immer';
import { getType } from 'typesafe-actions';
import { recordsActions, RecordsActions } from '../actions';
import createUniqueArray from '../../../utils/createUniqueArray';
import { RevisionRecord } from '../../../types/RevisionRecord';

export interface RevisionRecordsState {
  allProducts: number[],
  productsById: RevisionRecordProduct,
}

export interface RevisionRecordProduct {
  [productID: string] : RevisionRecords,
}

interface RevisionRecords {
  [frequency: string] : RevisionRecord,
}

export const initialState: RevisionRecordsState = {
  allProducts: [],
  productsById: {},
};

export const revisionRecordsReducer = produce<RevisionRecordsState, RecordsActions>(
  (state, action) => {
    switch (action.type) {
      case getType(recordsActions.revision.addRevision):
        const { payload: records } = action;
        const { frequency, productID } = action.meta;
        state.allProducts =
          createUniqueArray<number>([...state.allProducts, Number(productID)]);
        if (!state.productsById[action.meta.productID]) {
          state.productsById[productID] = { [frequency]: records };
        } else if (!state.productsById[action.meta.productID][frequency]) {
          state.productsById[productID][frequency] = records;
        } else {
          state.productsById[productID][frequency].allAreas =
            createUniqueArray<number>([...state.productsById[productID][frequency].allAreas, ...records.allAreas]);
          records.allAreas.forEach((area: number) => {
            state.productsById[productID][frequency][area] = { ...state.productsById[productID][frequency][area], ...records[area] };
            state.productsById[productID][frequency][area].allRecords = createUniqueArray<number>(
              [...state.productsById[productID][frequency][area].allRecords, ...records[area].allRecords]);
          });
        }
        return;
    }
  }, initialState,
);

export default revisionRecordsReducer;
