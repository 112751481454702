import { combineReducers } from 'redux';
import frequencyRecordsReducer from './frequency/reducers';
import futuresRecordReducer from './futures/reducers';
import revisionRecordsReducer from './revision/reducers';

export const recordsRootReducer = combineReducers({
  frequency: frequencyRecordsReducer,
  futures: futuresRecordReducer,
  revision: revisionRecordsReducer,
});
